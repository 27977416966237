import { REQUEST_STATUS_TYPE, REQUEST_STATUS_TYPE_ICONS } from "./request";

const makeHistory = (data) => {
    return {
        ...data,
        status_text() {
            if (this.status == "") return "Atualização"
            return REQUEST_STATUS_TYPE[this.status] ?? this.status
        },
        status_icon() {
            return REQUEST_STATUS_TYPE_ICONS[this.status] ?? { icon: "info", color: "black" }
        },
    }
}

export default makeHistory