import stringMask from "string-mask";


String.prototype.insert = function (index, string) {
    if (index > 0) {
        return this.substring(0, index) + string + this.substring(index);
    }
    return string + this;
};

export const currencyMask = (value) => {
    if (value == null) value = ""
    if (typeof value == 'number') value = value.toFixed(2)
    value = value.toString()
    value = value.replace(/\D/g, "");
    value = value.replaceAll(".", "")
    value = value.replaceAll(",", "")
    if (value.length == 0) return ""
    else if (value.length == 1) return "0.0" + value
    else if (value.length == 2) return "0." + value
    else value = value.insert(value.length - 2, ".")
    return parseFloat(value).toFixed(2)
}

export const numberMask = (value) => {
    if (value == null) value = "";
    value = value.toString()
    let negative = false
    if (value[0] == "-") {
        negative = true
    }
    return negative ? "-" + value.replace(/\D/g, "") : value.replace(/\D/g, "")
}

export const dateMask = (value) => {
    value = value.replaceAll("/", "").substring(0, 8)
    var formatter = new stringMask('##/##/####');
    return formatter.apply(value); // 123
}

export const defaultMask = (value, mask) => {
    value = value.replace(/\D/g, "");
    var formatter = new stringMask(mask);
    return formatter.apply(value); // 123
}

