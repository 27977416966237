<template>
	<div>
		<modal title="Confirmação" v-model="confirmPopup" :closeOnBackground="false" :showClose="false">
			<p>
				{{confirmData.message}}
			</p>
			<template #footer>
				<vs-button @click="confirmData.onCancel()" type="flat">Cancelar</vs-button>
				<vs-button @click="confirmData.onConfirm()">Continuar</vs-button>
			</template>

		</modal>

	</div>
</template>

<script>
import Modal from "./Modal.vue";

export default {
	name: 'StockWebMessages',
	components: { Modal },
	data() {
		return {
			confirmPopup: false,
			confirmData: {
				onConfirm: () => { },
				onCancel: () => { },
				message: "",
			},
			promiseRunning: false,
		};
	},

	mounted() {
		this.$app.messages = this
		this.$app.promise = async (promise) => {
			if (this.promiseRunning) {
				this.$app.promise_queue.push(promise)
				return;
			}
			this.loading(true)
			try {
				await promise()
				while (this.$app.promise_queue.length > 0) {
					await this.$app.promise_queue[0]()
					this.$app.promise_queue.splice(0, 1)
				}
			} catch (e) {
				this.error(e)
			}
			this.loading(false)
		}

	},

	methods: {
		loading(show) {
			this.promiseRunning = show;
			if (show) {
				this.$vs.loading()
			}
			else {
				this.$vs.loading.close()
			}
		},
		error(e) {
			if (typeof e == "string") {
				this.$vs.notify({ title: 'Erro', text: e, color: 'danger', position: 'top-right' })
			}
			else if (typeof e === "object") {
				if (e.response?.data?.errors?.length > 0) {
					let m = ""
					for (const t of e.response?.data.errors) {
						m += m == "" ? t : "<br>" + t
					}
					this.$vs.notify({ title: 'Erro', text: m, color: 'danger', position: 'top-right' })
					return
				}
				if (e.response?.data?.message != null) {
					this.$vs.notify({ title: 'Erro', text: e.response?.data?.message, color: 'danger', position: 'top-right' })
					return
				}
				this.$vs.notify({ title: 'Erro', text: e.message, color: 'danger', position: 'top-right' })
			}
		},
		success(msg) {
			this.$vs.notify({ text: msg, color: 'success', position: 'top-right' })
		},
		confirm({ message, onConfirm, onCancel } = {}) {
			this.confirmPopup = true;
			this.confirmData = {
				message,
				onConfirm: () => {
					if (onConfirm != null) onConfirm(); this.confirmPopup = false
				},
				onCancel: () => { if (onCancel != null) onCancel(); this.confirmPopup = false },
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.footer {
	border-top: 1px solid #e8e8e8;
	margin-top: 10px;
	padding-top: 10px;
	display: flex;
	justify-content: right;
}
</style>