import moment from "moment";

export const ValidationSchemas = {
    required(v) {
        if (v == "" || v == null) return "Campo requerido"
        return null;
    },
    min(v, min) {
        if (v == null || v.length < min) return `Mínimo ${min} caracteres`
        return null;
    },
    moreThan(v, target) {
        v = parseFloat(v)
        if (isNaN(v) || v <= target) return `Deve ser maior que ${target}`
        return null;
    },
    moreOrEqualThan(v, target) {
        v = parseFloat(v)
        if (isNaN(v) || v < target) return `Deve ser maior que ${target}`
        return null;
    },
    maxValue(v, target) {
        v = parseFloat(v)
        if (isNaN(v)) v = 0;
        if (v > target) return `Não pode ser maior que ${target}`
        return null;
    },
    date(v) {
        if (v.indexOf("/") > -1 && v.length == 10) {
            const t = v.split("/")
            v = `${t[2]}-${t[1]}-${t[0]}`
        }
        const d = moment(v)
        if (d.format("YYYY") == "Invalid date" || v.length != 10) return `Não é uma data valida`
        return null;
    }
}

export const ValidationForm = () => {
    return {
        fields: {},
        ignore: [],
        register(name, validator) {
            this.fields[name] = validator
        },
        unregister(name) {
            delete this.fields[name]
        },
        validate() {
            const keys = Object.getOwnPropertyNames(this.fields)
            let okay = true
            console.log(this.fields)
            for (const k of keys) {
                if (this.ignore.indexOf(k) == -1) {
                    if (this.fields[k].validate != null)
                        if (!this.fields[k].validate()) {
                            okay = false
                        }
                }

            }
            return okay
        },
        clear() {
            const keys = Object.getOwnPropertyNames(this.fields)
            for (const k of keys) {
                if (this.fields[k].validate != null) {
                    this.fields[k].clear()
                }

            }
        }
    }
}

// const Validations = {
//     fieldData(watch, mask) {
//         return {
//             danger: false,
//             "danger-text": "",
//             message: "",
//             "val-icon-danger": "close",
//             execute: watch,
//             mask
//         }
//     },
//     make(vm, formName, validations) {
//         const instance = {
//             vm,
//             required(field, value) {
//                 if (value == "") {
//                     field.danger = true;
//                     field["danger-text"] = "Campo requerido"
//                 }
//             }
//         }
//         const keys = Object.getOwnPropertyNames(validations)
//         for (const k of keys) {
//             const field = validations[k]
//             if (field.execute != null) {
//                 console.log(`${formName}.${k}`)
//                 vm.$watch(`${formName}.${k}`, (value) => {
//                     const vals = field.execute(instance)
//                     field.danger = false;
//                     if (field.mask != null) {
//                         value = field.mask(value)
//                         vm[formName][k] = value;
//                     }
//                     for (const v of vals) {
//                         v(field, value)
//                         if (field.danger) break
//                     }
//                 })
//             }

//         }
//         const validate = () => {
//             let okay = true;
//             const keys = Object.getOwnPropertyNames(validations)
//             for (const k of keys) {
//                 const field = validations[k]
//                 if (field.execute != null) {
//                     const vals = field.execute(instance)
//                     field.danger = false;
//                     let value = vm[formName][k]
//                     if (field.mask != null) {
//                         value = field.mask(value)
//                         vm[formName][k] = value;
//                     }
//                     for (const v of vals) {
//                         v(field, value)
//                         if (field.danger) {
//                             okay = false;
//                             break
//                         }
//                     }
//                 }

//             }
//             return okay
//         }
//         return {
//             validate
//         }
//     }
// }
// export default Validations;