<template>
	<div>
		<modal v-model="popup" title="Notificacões">
			<div class="not-items">
				<div class="notification" v-for="not in news.results" :key="not.id">
					<div class="not-content" @click="process(not)">
						<div class="dflex gap"><vs-icon color="primary" v-if="!not.read">trip_origin</vs-icon>

							<div :style="`font-weight:${not.read?'normal':'bold'}`">
								{{ not.title }}
							</div>
						</div>
						<div style="white-space:pre-wrap;font-size:13px">{{ message(not) }}</div>
						<div class="caption"> {{ $format.dshow(not.created_at)}}</div>
					</div>

				</div>
			</div>
		</modal>

	</div>

</template>

<script>
import Api from '@/modules/api/api';
import Modal from '@/components/Modal.vue';
export default {
	components: { Modal },
	name: "StockWebNotification",
	data() {
		return {
			news: {
				total: 0,
				results: [],
			},
			popup: false
		};
	},

	mounted() {
		this.getNews()
		this.$app.notifications = this
	},

	computed: {
		totalNews() {
			let t = 0;
			for (const n of this.news.results) {
				if (!n.read) t++
			}

			return t
		}
	},
	watch: {
		totalNews(v) {
			this.$store.commit("all", { hasNotification: v > 0 })
		}
	},

	methods: {
		message(not) {
			if (not.payload?.request != null) return `Valor: ${this.$format.money(not.payload.request.total_with_discount())} | Vendedor: ${not.payload.request.seller_name}`
			return not.message
		},
		show() {
			this.popup = true;
		},
		async process(item) {
			if (item.payload?.request != null) {
				location.href = "/#/requests/details/" + item.payload.request.id
			}
			else if (item.payload) {
				if (item.payload.redirect != null) location.href = "/#/" + item.payload.redirect
			}
			if (!item.read) {
				try {
					item.read = true
					await Api.notification.markReadOwner(item.id)

				} catch (e) {
					this.$app.messages.error(e)
				}
			}
			this.popup = false

		},
		async getNews() {
			try {
				this.news = await Api.notification.owner({ currentPage: 1, pageSize: 30 })

			} catch (e) {
				this.$app.messages.error(e)
			}
			setTimeout(() => {
				this.getNews()
			}, 15000)
		}
	},
};
</script>

<style lang="scss" scoped>
@keyframes anim-red {
	0% {
		background-color: red;
	}
	30% {
		background-color: transparent;
	}
	50% {
		background-color: red;
	}
}

.notification-icon {
	position: relative;
	cursor: pointer;
	.new {
		transition: 0.2;
		border-radius: 100px;
		height: 10px;
		width: 10px;
		position: absolute;
		top: -5px;
		right: -5px;
		background: red;
		animation-name: anim-red;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}
}
.notification {
	display: flex;
	flex-direction: column !important;
	align-items: start !important;
	gap: 5px;
}
.not-content {
	transition: 0.2s;
	width: 100%;
	cursor: pointer;
	border-radius: 10px;
}
.not-content:hover {
	background-color: #fafafa;
}

.not-items {
	max-height: 50vh;
	overflow: auto;
	display: flex;
	flex-direction: column;
}
.not-items > *::after {
	content: " ";
	width: 100%;
	margin: 10px 0px;
	border-bottom: 1px solid #e8e8e8;
}
.not-items > *:last-child::after {
	width: 0;
}
</style>