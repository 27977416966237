<template>
	<div class="container">
		<img src="/img/icons/stock-icon-white.png" style="width:30px;margin-top:20px;margin-bottom:20px" />
		<a href="#">
			<vs-tooltip :text="$store.state.user?.name" position="right">

			</vs-tooltip>
		</a>

		<side-menu-item :description='$store.state.user?.name' to="#" @click="$app.instance.$refs.modalUser.show()">
			<div class="user">
				{{$store.state.user?.name?.[0]}}
			</div>
		</side-menu-item>

		<side-menu-item description='Notificações' icon="notifications" to="#" :alert="$store.state.hasNotification" @click="$app.notifications.show()"></side-menu-item>
		<side-menu-item v-for="item,i in items" :key="i" :description="item.title" :icon="item.icon" :active="isCurrentPage(item.url)" :to="item.url"></side-menu-item>
	</div>
</template>

<script>
import SideMenuItem from './SideMenuItem.vue';
export default {
	components: { SideMenuItem },
	name: "StockWebSideMenu",
	data() {
		return {
			items: [
				{
					icon: "storefront",
					title: "Configurações da loja",
					url: "/#/store-settings",
				},
				{
					icon: "face",
					title: "Clientes",
					url: "/#/clients",
				},
				{
					icon: "shopping_cart",
					title: "Pedidos",
					url: "/#/requests",
				},
				{
					icon: "list_alt",
					title: "Produtos",
					url: "/#/products",
				},
				{
					icon: "sell",
					title: "Tags",
					url: "/#/tags",
				},
				{
					icon: "group",
					title: "Vendedores",
					url: "/#/sellers",
				},

				{
					icon: "settings",
					title: "Configurações",
					url: "/#/settings",
				},
				{
					icon: "receipt_long",
					title: "Relatórios & Gestão",
					url: "/#/reports",
				},
				{
					icon: "savings",
					title: "Cobrança",
					url: "/#/billing",
				},

			],
			userData: { name: "", phone: "" },
			path: window.location.href
		};
	},
	watch: {
		'$route'() {
			this.path = window.location.href
		}
	},
	mounted() {
	},
	methods: {
		isCurrentPage(url) {
			return this.path.toLowerCase().indexOf(url.toLowerCase()) > -1;
		},

	},
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.user {
	// background-color: black;
	opacity: 0.8;
	color: white;
	width: 27px;
	height: 27px;
	font-size: 13px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
	border: 1.5px solid white;
}
.user:hover {
	opacity: 1;
}
</style>