<template>
	<div class="app-modal">
		<div :class="value?'modal modal-active':'modal'">
			<div class="modal-background" @click="backgroundClose"></div>
			<div class="modal-content" :style="styleContent">
				<div v-if="title != null || $slots.title != null" class="header">
					<!-- <app-icon i="info" /> -->
					<div style="margin-top:-2px;flex-grow:1">
						{{ title }}
						<slot name="title"></slot>
						<div style="font-size:14px;font-weight:normal">{{ subtitle }}</div>
					</div>
					<app-icon i="close" v-if="onConfirm == null && showClose" @click="close" style="cursor:pointer" />
				</div>
				<div class="content">
					<slot></slot>
				</div>
				<div class="footer">
					<slot name="footer">
						<div style="flex-grow:1"></div>
						<vs-button type="border" @click="cancel" v-if="showCancel">Fechar</vs-button>
						<vs-button @click="confirm" v-if="onConfirm != null">Continuar</vs-button>
					</slot>

				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: 'StockModal',
	props: {
		value: Boolean,
		title: String,
		subtitle: String,
		onConfirm: Function,
		styleContent: {
			default: "min-width:500px",
			type: String
		},
		closeOnBackground: {
			default: true,
			type: Boolean
		},
		showClose: {
			default: true,
			type: Boolean
		},
		showCancel: {
			default: true,
			type: Boolean
		}
	},
	data() {
		return {
			show: false
		};
	},
	methods: {
		backgroundClose() {
			if (this.closeOnBackground) this.close()
		},
		close(force = false) {
			if (!force) {
				if (this.onConfirm != null) return;
			}
			this.$emit("input", false)
		},
		cancel() {
			this.close(true)
		},
		async confirm() {
			const result = await this.onConfirm()
			if (result != false) {
				this.close(true)
			}

		}
	},
};
</script>

<style lang="scss" scoped>
.app-modal {
	position: fixed;
	top: 0px;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	pointer-events: none;

	// background: #00ff0033;
	.modal {
		position: relative;
		justify-content: center;
		margin: 0 auto;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.modal-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(31, 32, 41, 0.75);
		opacity: 0;
		transition: opacity 250ms ease;
	}
	.modal-active .modal-background {
		opacity: 1;
	}
	.modal.modal-active {
		pointer-events: auto;
		opacity: 1;
		transition: all 300ms ease-in-out;
	}
	@media (max-width: 600px) {
		.modal-content {
			width: 90vw !important;
			min-width: 90vw !important;
			// background-color: red !important;
		}
	}
	.modal-content {
		position: relative;
		display: block;
		margin: 0 auto;
		border-radius: 10px;
		overflow: hidden;
		background-color: #fff;
		align-self: center;
		box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
		opacity: 0;
		transform: scale(0.6);
		transition: opacity 100ms 0ms ease, transform 100ms 0ms ease;
		.content {
			padding: 20px;
			max-height: calc(100vh - 150px);
			overflow: auto;
			transition: 0.2s;
		}
		.footer {
			border-top: 1px solid #e8e8e8;
			width: 100%;
			padding: 20px;
			display: flex;
			gap: 10px;
			align-items: center;
		}
		.header {
			font-weight: bold;
			font-size: 20px;
			padding: 20px;
			padding-bottom: 0px;
			display: flex;
			gap: 10px;
			// align-items: center;
		}
	}
	.modal.modal-active .modal-content {
		opacity: 1;
		transform: scale(1);
		transition: opacity 100ms 150ms ease, transform 100ms 150ms ease;
	}
}
</style>