import meta_data from "@/meta_data";
import axios from "axios"

console.log(process.env)

const ApiProvider = {
    host: process.env.VUE_APP_API_HOST,
    axios() {
        return axios;
    },
    headers() {
        return { authorization: "Bearer " + localStorage.getItem("auth"), owner: localStorage.getItem("owner_id"), "seller-id": localStorage.getItem("seller_id"), version_admin: meta_data.version }
    },
    async get(url, params = {}) {
        let temp = ""
        if (params != null) {
            const keys = Object.getOwnPropertyNames(params)
            for (const k of keys) {
                temp += temp == "" ? `?${k}=${params[k]}` : `&${k}=${params[k]}`
            }
        }
        return (await axios.get(this.host + url + temp, { headers: this.headers() })).data

    },
    async post(url, data = {}) {
        return (await axios.post(this.host + url, data, { headers: this.headers() })).data
    },
    async delete(url) {
        return (await axios.delete(this.host + url, { headers: this.headers() })).data
    }

}

export default ApiProvider