<template>
	<div class="layout">
		<div class="image media-hidden-md">
			<div class="background"></div>
		</div>
		<div class="content">
			<div class="header">
				<!-- <img src="/img/icons/stock-icon.png" style="width:30px" />
				<div class="stock">Stock Store</div> -->
			</div>
			<div class="slot">
				<h1 class="title">{{title}}</h1>
				<slot></slot>
			</div>
			<div class="bottom">
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'StockWebLayoutAuth',
	props: {
		title: String,
	},
	data() {
		return {

		};
	},

	mounted() {

	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>
.layout {
	padding: 20px 0;
	display: flex;
	gap: 70px;
	height: 100%;

	.image {
		border-radius: 10px;
		overflow: hidden;
		width: 35vw;
		background-color: red;
		background: url("@/assets/auth.png");
		object-fit: cover;
		background-position: center;
		.background {
			width: 100%;
			height: 100%;
			background-color: rgba(var(--vs-primary), 0.7);
		}
	}
	.content {
		flex-grow: 1;
		padding: 20px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 20px;
		.header {
			display: flex;
			align-items: center;
			gap: 10px;
			font-weight: bold;
		}
		.title {
			margin-bottom: 20px;
		}
	}
}
</style>