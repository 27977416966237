import moment from "moment"

const makePlan = (data) => {
    return {
        ...data,
        type_text() {
            if (data.details?.type == "FREE") return "Gratuito"
            return data.details?.type
        },
        period() {
            return moment(data.expires_at).diff(data.started_at, 'days') + 1
        },
    }
}

export default makePlan