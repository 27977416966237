

import makeClient from "@/models/client";
import makePayment from "@/models/payment";
import makeRequest from "@/models/request";
import makeHistory from "@/models/history";
import makePlan from "@/models/plan";
import ApiProvider from "./api_provider";
import axios from "axios";
import ApiSeller from "./api_seller";


const Api = {
    provider() {
        return ApiProvider;
    },
    auth: {
        async login(data) {
            return (await ApiProvider.post("auth/login", data)).data
        },
        async permissions() {
            return (await ApiProvider.get("auth/permissions/all")).data
        },
        async currentUser() {
            return (await ApiProvider.get("auth/current_user")).data
        },
        async currentUserSave(data) {
            return (await ApiProvider.post("auth/current_user/save", data)).data
        },
    },
    notification: {
        async owner(options) {
            const result = (await ApiProvider.get("notifications/owner", options)).data
            for (const n of result.results) {

                if (n.payload?.request != null) {
                    n.payload.request = makeRequest(n.payload?.request)
                }
            }

            return result
        },
        async markReadOwner(id) {
            return (await ApiProvider.post("notifications/owner/mark/" + id)).data
        },
    },

    client: {
        async search(options) {
            const result = (await ApiProvider.get("client/search", options)).data
            result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async getById(id) {
            return makeClient((await ApiProvider.get("client/get/" + id)).data)
        },
        async save(data) {
            return (await ApiProvider.post("client/save", data)).data
        },
        async delete(id) {
            return (await ApiProvider.delete("client/delete/" + id)).data
        },
        async syncCredit(id) {
            return (await ApiProvider.post("client/sync-credit/" + id)).data
        },
    },
    products: {
        async search(options) {
            const result = (await ApiProvider.get("product/search", options)).data
            // result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async getById(id) {
            return (await ApiProvider.get("product/get/" + id)).data
        },
        async save(data) {
            return (await ApiProvider.post("product/save", data)).data
        },
        async addBalance(data) {
            return (await ApiProvider.post("product/addBalance", data)).data
        },
        async addBalanceMultiple(data) {
            return (await ApiProvider.post("product/addBalance/multiple", data)).data
        },
        async historyBalance(id, options) {
            const result = (await ApiProvider.get("product/history_balance/" + id, options)).data
            // result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async uploadImage(data) {
            const result = (await ApiProvider.post("product/upload-image", data)).data
            return result
        },
        async removeImage(image_id) {
            const result = (await ApiProvider.delete("product/remove-image/" + image_id)).data
            return result
        },
        async delete(id) {
            return (await ApiProvider.delete("product/delete/" + id)).data
        }
    },
    tags: {
        async search(options) {
            const result = (await ApiProvider.get("tags/search", options)).data
            // result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async getById(id) {
            return (await ApiProvider.get("tags/get/" + id)).data
        },
        async save(data) {
            return (await ApiProvider.post("tags/save", data)).data
        },

        async addProducts(ids, tag_id) {
            return (await ApiProvider.post("tags/product/add", { products: ids, tag_id })).data
        },
        async removeProducts(ids, tag_id) {
            return (await ApiProvider.post("tags/product/remove", { products: ids, tag_id })).data
        },
        async delete(id) {
            return (await ApiProvider.delete("tags/delete/" + id)).data
        }
    },
    sellers: {
        async search(options) {
            const result = (await ApiProvider.get("seller/search", options)).data
            // result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async getById(id) {
            return (await ApiProvider.get("seller/get/" + id)).data
        },
        async sync(id) {
            return (await ApiProvider.get("seller/sync/" + id)).data
        },
        async save(data) {
            return (await ApiProvider.post("seller/save", data)).data
        },
        async delete(id) {
            return (await ApiProvider.delete("seller/delete/" + id)).data
        }
    },
    requests: {
        async search(options) {
            const result = (await ApiProvider.get("request/search", options)).data
            result.results = result.results.map((i) => makeRequest(i))
            return result
        },
        async getById(id) {
            return makeRequest((await ApiProvider.get("request/get/" + id)).data)
        },
        async create(data) {
            return makeRequest((await ApiProvider.post("request/create", data)).data)
        },
        async delete(id) {
            return (await ApiProvider.delete("request/delete/" + id)).data
        },
        async addHistory(data) {
            return (await ApiProvider.post("request/add/history", data)).data
        },
        async getHistory(id) {
            const data = (await ApiProvider.get(`request/${id}/history`)).data
            return data.map(i => makeHistory(i))
        },
        async signatureSave(data) {
            return (await ApiProvider.post("request/signature/save", data)).data
        },
        async cancel(request_id) {
            return (await ApiProvider.post("request/cancel", { request_id })).data
        },
    },
    payments: {
        async create(data) {
            return makePayment((await ApiProvider.post("payment/create", data)).data)
        },
        async delete(request_id) {
            return (await ApiProvider.delete("payment/delete/" + request_id)).data
        },
        async search(options) {
            const result = (await ApiProvider.get("payment/search", options)).data
            result.results = result.results.map((i) => makePayment(i))
            return result
        },

        async listByClient(id, options) {
            const result = (await ApiProvider.get("payment/list/client/" + id, options)).data
            result.results = result.results.map((i) => makePayment(i))
            return result
        },
        async signatureSave(data) {
            return (await ApiProvider.post("payment/signature/save", data)).data
        },
        async paySeller(items) {
            return (await ApiProvider.post("payment/pay/seller", { items })).data
        },
        async reverse(payment_id) {
            return makePayment((await ApiProvider.post("payment/reverse", { payment_id })).data)
        },
        async changeType(payment_id, type) {
            return (await ApiProvider.post("payment/change-type", { payment_id, type })).data
        },
    },



    owner: {
        async saveConfig(data) {
            return (await ApiProvider.post("owner/configs/save", data)).data
        },

        async config(owner_id) {
            return (await ApiProvider.get("owner/configs/" + owner_id)).data
        },
        async currentConfig() {
            return (await ApiProvider.get("owner/configs/current")).data
        },
        async save(data) {
            return (await ApiProvider.post("owner/save", data)).data
        },
        async create(data) {
            return (await ApiProvider.post("owner/create", data)).data
        },
        async current() {
            return (await ApiProvider.get("owner/current")).data
        },
    },
    reports: {
        async comissao(params) {

            const result = (await ApiProvider.get("reports/comissao", params)).data
            return { payments: result.payments.map((i) => makePayment(i)), seller: result.seller }
        },
        async minimo() {
            const result = (await ApiProvider.get("reports/minimum")).data
            return result;
        },
        async requestResume(params) {
            const result = (await ApiProvider.get("reports/request-resume", params)).data
            return { requests: result.requests.map((i) => makeRequest(i)) }
        },
        async stockResume(params) {
            const result = (await ApiProvider.get("reports/stock-resume", params)).data
            return result
        },
    },
    store: {
        async save(data) {
            return (await ApiProvider.post("store/save", data)).data
        },
        async getById(id) {
            return (await ApiProvider.get("store/id/" + id)).data
        },
        async current() {
            return (await ApiProvider.get("store/current")).data
        },
        async getByIdentification(identification) {
            return (await ApiProvider.get("store/@" + identification)).data
        },
        async availableIdentification(identification) {
            return (await ApiProvider.get("store/available/@" + identification)).data
        },
        async search(id, options) {
            const result = (await ApiProvider.get(`store/${id}/search`, options)).data
            return result
        }
    },
    others: {
        async meta() {
            return (await ApiProvider.get("meta"))
        },
        async uploadGeneralGallery(path, base64) {
            return (await ApiProvider.post("others/upload-general-gallery", { path, base64 })).data
        },
        correios: {
            async findCep(cep) {
                return (await ApiProvider.get("others/correios/find-cep/" + cep)).data
            },
            async calculate(fromCep, toCep, weight, length, width, height) {
                return (await ApiProvider.get("others/correios/calculate", {
                    fromCep, toCep, weight, length, width, height
                })).data
            },
        }
    },
    gallery: {
        async upload(base64) {
            return (await ApiProvider.post("gallery/upload", { base64 })).data
        },
        async delete(id) {
            return (await ApiProvider.post("gallery/delete/" + id)).data
        },
        async get() {
            return (await ApiProvider.get("gallery")).data
        },
    },
    billing: {
        async next() {
            const result = (await ApiProvider.get("billing/next")).data
            result.plan = makePlan(result.plan)
            return result;
        },
        async pay(data) {
            const result = (await ApiProvider.post("billing/pay", data)).data
            return result;
        },
        async deleteCurrent(data) {
            const result = (await ApiProvider.delete("billing/delete/current", data)).data
            return result;
        },
    },

    variations: {
        async getTemplates() {
            const result = (await ApiProvider.get("variations/templates")).data
            return result;
        },
        async getTemplate(id) {
            const result = (await ApiProvider.get("variations/template/" + id)).data
            return result;
        },
        async get(id) {
            const result = (await ApiProvider.get("variations/" + id)).data
            return result;
        },
        // async save() {
        //     const result = (await ApiProvider.post("variations/save")).data
        //     return result;
        // },
        async saveTemplate(data) {
            const result = (await ApiProvider.post("variations/template/save", data)).data
            return result;
        },
    },

    mercado_pago: {
        async creditCardToken(number, document, name, expiration, security) {
            const result = await axios.post("https://api.mercadopago.com/v1/card_tokens?public_key=TEST-d634f2ea-3f8e-4759-8e58-9f4f4ef15c3e", {
                "card_number": number.replaceAll(" ", ""),
                "cardholder": {
                    "identification": {
                        "number": document.replaceAll(" ", ""),
                        "type": "CPF"
                    },
                    "name": name
                },
                "expiration_month": expiration.split("/")[0],
                "expiration_year": expiration.split("/")[1],
                "security_code": security
            },)
            return result.data;
        }
    },

    api_seller: ApiSeller

}

export default Api;