const storeConfig = {
    state: {
        cart: [],
        user: null,
        loading_header: false,
        store_edit_section_selected: null,
        hasNotification: false,
    },
    mutations: {
        cart(state, data) {
            state.cart = [...data];
        },
        user(state, data) {
            state.user = data;
        },
        loading_header(state, value) {
            state.loading_header = value
        },
        store_edit_section_selected(state, value) {
            state.store_edit_section_selected = value;
        },
        all(state, value) {
            const keys = Object.getOwnPropertyNames(value);
            for (const k of keys) {
                state[k] = value[k]
            }
        }
    }
}
export default storeConfig;