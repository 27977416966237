const $theme = {
    colors: {
        primary: '#5352ed',
        success: 'rgb(23, 201, 100)',
        danger: 'rgb(242, 19, 93)',
        warning: 'rgb(255, 130, 0)',
        dark: 'rgb(36, 33, 69)',
        vars: {
            primary: "rgba(var(--vs-primary), 1)",
            danger: "rgba(var(--vs-danger), 1)",
            warning: "rgba(var(--vs-warning), 1)",
            success: "rgba(var(--vs-success), 1)"
        }
    }
}
export default $theme