<template>
	<div style="width:100%" @click="$emit('click')">
		<a :href="to" :class="active?`.side-menu-item-active`:``">
			<vs-tooltip :text="description" position="right">
				<div style="position:relative">
					<slot>
						<app-icon :i="icon"></app-icon>
					</slot>

					<div class="alert-dot" v-if="alert"></div>
				</div>

			</vs-tooltip>
		</a>
	</div>

</template>

<script>

export default {
	name: "StockWebNotification",
	props: {
		to: String,
		description: String,
		alert: Boolean,
		icon: String,
		active: Boolean
	}
};
</script>

<style lang="scss" scoped>
@keyframes anim-red {
	0% {
		background-color: red;
	}
	30% {
		background-color: transparent;
	}
	50% {
		background-color: red;
	}
}
.alert-dot {
	transition: 0.2;
	border-radius: 100px;
	height: 10px;
	width: 10px;
	position: absolute;
	top: -5px;
	right: -5px;
	background: red;
	animation-name: anim-red;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
}
a {
	display: flex;
	justify-content: center;
	padding: 10px;
	cursor: pointer;
	width: 100%;
	transition: 0.2s;
	color: #ffffffaa;
}
a:hover {
	background-color: #00000033;
	transition: 0.2s;
	color: white;
	text-decoration: none;
}
.side-menu-active {
	background-color: #00000033;
	color: white;
}
</style>