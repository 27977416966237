<template>
	<div>
		<div class="menu">
			<a href="#" @click="$app.instance.$refs.modalUser.show()">
				<div class="user">
					{{$store.state.user?.name?.[0]}}
				</div>

			</a>
			<a :href="item.url" v-for="item,i in items" :key="i" :class="isCurrentPage(item.url)?'current':''">
				<vs-icon :icon="item.icon" size="30px"></vs-icon>
			</a>
		</div>
		<div class="container">
			<slot></slot>
		</div>

	</div>
</template>

<script>
export default {
	name: "StockWebAppSeller",
	data() {
		return {
			items: [
				{
					icon: "home",
					title: "Início",
					url: "/#/home",
				},
				{
					icon: "local_mall",
					title: "Pedidos",
					url: "/#/seller/requests",
				},
				{
					icon: "face",
					title: "Clientes",
					url: "/#/seller/clients",
				},
				{
					icon: "sell",
					title: "Produtos",
					url: "/#/seller/products",
				},

				{
					icon: "bar_chart",
					title: "Resumo",
					url: "/#/seller/report/comissao",
				},

			],
			userData: { name: "" },
			path: window.location.href,
		};
	},
	mounted() {
	},
	watch: {
		'$route'() {
			this.path = window.location.href
		}
	},
	methods: {
		isCurrentPage(url) {
			return this.path.toLowerCase().indexOf(url.toLowerCase()) > -1;
		},
	},
	components: {}
};
</script>

<style lang="scss" scoped>
.container {
	height: calc(100vh - 50px);
	overflow: auto;
	padding-top: 64px;
}
.menu {
	box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
	align-items: center;
	z-index: 1000;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	background-color: #5352ed;
	height: 50px;
	width: 100vw;
}
a {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	cursor: pointer;
	transition: 0.2s;
	color: #ffffffaa;
}
a:hover {
	background-color: #00000033;
	transition: 0.2s;
	color: white;
}
.current {
	background-color: #00000033;
	color: white;
}
.user {
	background-color: black;
	color: white;
	width: 30px;
	height: 30px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1000px;
}
</style>