export const PAYMENT_TYPE_TEXT = (type) => {
    const STATUS = {
        MONEY: "Dinheiro",
        PIX: "PIX",
        DEBIT_CARD: "Cartão de débito",
        CREDIT_CARD: "Cartão de crédito",
        CLIENT_CREDIT: "Crédito cliente",
        REVERSE: "Estorno",
    }
    return STATUS[type] ?? ""
}

export const PAYMENT_TYPE_TEXT_OPTIONS = () => {
    const keys = Object.getOwnPropertyNames(PAYMENT_TYPE_TEXT)
    return keys.map(k => {
        return {
            value: k,
            text: PAYMENT_TYPE_TEXT[k]
        }
    })
}

const makePayment = (data) => {
    return {
        ...data,
        type_text() {
            return PAYMENT_TYPE_TEXT(this.type)
        },

    }
}

export default makePayment