<template>
	<div>
		<Modal v-model="show" :title="`Nova atualização`" :showClose="false" :closeOnBackground="false">
			Uma nova atualização da StockC foi encontrada, por favor recarregue sua página.
			<p> v{{ currentVersion }} -> v{{ fetchVersion }} </p>
			<template #footer>
				<vs-button @click="refresh">Clique aqui para atualizar sua página</vs-button>
			</template>
		</Modal>

	</div>
</template>

<script>
import axios from 'axios';
import meta_data from '@/meta_data';
import Modal from './Modal.vue';
export default {
	components: { Modal },
	name: 'StockVersionValidate',

	data() {
		return {
			show: false,
			fetchVersion: "",
			currentVersion: meta_data.version
		};
	},

	mounted() {
		this.fetch()
	},

	methods: {
		refresh() {
			location.reload()
		},
		async fetch() {
			try {
				const res = await axios.get("/version.txt?d=" + new Date().getTime())
				this.fetchVersion = res.data
				if (res.data != meta_data.version) this.show = true
			} catch (e) {
				console.log("Version problem:", e)
			}
			setTimeout(() => {
				this.fetch()
			}, 60000)
		}
	},
};
</script>

<style lang="scss" scoped>
</style>