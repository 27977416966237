import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/clients/ClientsView.vue')
  },
  {
    path: '/clients/add',
    name: 'clients.add',
    component: () => import('../views/clients/ClientsFormView.vue')
  },
  {
    path: '/clients/edit/:id',
    name: 'clients.edit',
    component: () => import('../views/clients/ClientsFormView.vue')
  },
  {
    path: '/clients/details/:id',
    name: 'clients.datails',
    component: () => import('../views/clients/ClientsDetailsView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products/ProductsView.vue')
  },
  {
    path: '/products/add',
    name: 'products.add',
    component: () => import('../views/products/ProductsFormView.vue')
  },
  {
    path: '/products/edit/:id',
    name: 'products.edit',
    component: () => import('../views/products/ProductsFormView.vue')
  },
  {
    path: '/products/details/:id',
    name: 'products.details',
    component: () => import('../views/products/ProductsDetailsView.vue')
  },
  {
    path: '/store-settings/product/:id',
    name: 'products.details2',
    component: () => import('../views/products/ProductsDetailsView.vue')
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('../views/tags/TagsView.vue')
  },
  {
    path: '/tags/add',
    name: 'tags.add',
    component: () => import('../views/tags/TagsFormView.vue')
  },
  {
    path: '/tags/edit/:id',
    name: 'tags.edit',
    component: () => import('../views/tags/TagsFormView.vue')
  },
  {
    path: '/sellers',
    name: 'sellers',
    component: () => import('../views/sellers/SellersView.vue')
  },
  {
    path: '/sellers/add',
    name: 'sellers.add',
    component: () => import('../views/sellers/SellersFormView.vue')
  },
  {
    path: '/sellers/edit/:id',
    name: 'sellers.edit',
    component: () => import('../views/sellers/SellersFormView.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/requests/RequestsView.vue')
  },
  {
    path: '/requests/add',
    name: 'requests.add',
    component: () => import('../views/requests/RequestsFormView.vue')
  },
  {
    path: '/requests/details/:id',
    name: 'requests.details',
    component: () => import('../views/requests/RequestsDetailsView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings/Settings.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/reports/Reports.vue')
  },
  {
    path: '/reports/comissao',
    name: 'reports.comissao',
    component: () => import('../views/reports/ReportComissao.vue')
  },
  {
    path: '/reports/minimo',
    name: 'reports.minimo',
    component: () => import('../views/reports/ReportMinimo.vue')
  },
  {
    path: '/reports/request-resume',
    name: 'reports.request-resume',
    component: () => import('../views/reports/ReportRequestResume.vue')
  },
  {
    path: '/store-settings',
    name: 'reports.request-resume',
    component: () => import('../views/store_settings/StoreSettings.vue')
  },

  {
    path: '/billing',
    name: 'billing',
    component: () => import('../views/billing/Billing.vue')
  },


  //#########################
  {
    path: '/seller/clients',
    name: 'clients',
    component: () => import('../views/app_seller/clients/ClientsView.vue')
  },
  {
    path: '/seller/clients/add',
    name: 'clients.add',
    component: () => import('../views/app_seller/clients/ClientsFormView.vue')
  },
  {
    path: '/seller/clients/edit/:id',
    name: 'clients.edit',
    component: () => import('../views/app_seller/clients/ClientsFormView.vue')
  },
  {
    path: '/seller/clients/details/:id',
    name: 'clients.datails',
    component: () => import('../views/app_seller/clients/ClientsDetailsView.vue')
  },

  {
    path: '/seller/requests',
    name: 'requests',
    component: () => import('../views/app_seller/requests/RequestsView.vue')
  },
  {
    path: '/seller/requests/add',
    name: 'requests.add',
    component: () => import('../views/app_seller/requests/RequestsFormView.vue')
  },
  {
    path: '/seller/requests/details/:id',
    name: 'requests.details',
    component: () => import('../views/app_seller/requests/RequestsDetailsView.vue')
  },
  {
    path: '/seller/report/comissao',
    name: 'seller.report.comissao',
    component: () => import('../views/app_seller/reports/ReportComissao.vue')
  },
  {
    path: '/seller/products',
    name: 'seller.products',
    component: () => import('../views/app_seller/products/ProductsView.vue')
  },
  {
    path: '/seller/products/details/:id',
    name: 'seller.product',
    component: () => import('../views/app_seller/products/ProductsDetailsView.vue')
  },

  //################################

]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
