import makeHistory from "./history"
import makePayment from "./payment"

export const REQUEST_STATUS_TYPE = {
    OPENED: "Aberto",
    PAID: "Pago",
    SENDED: "Enviado",
    DONE: "Finalizado",
    CANCELLED: "Cancelado",
    PROCESSING: "Processando",
    SEPARATION: "Em separação",
    WAITING_PAYMENT: "Aguardando pagamento"
}

export const REQUEST_STATUS_TYPE_ICONS = {
    OPENED: { icon: "add", color: "#3498db" },
    PAID: { icon: "paid", color: "#9b59b6" },
    SENDED: { icon: "local_shipping", color: "#2c3e50" },
    DONE: { icon: "check_circle", color: "#2ecc71" },
    CANCELLED: { icon: "block", color: "#e74c3c" },
    WAITING_PAYMENT: { icon: "schedule", color: "#f39c12" },
    PROCESSING: { icon: "deployed_code_history", color: "#4a69bd" },
    SEPARATION: { icon: "deployed_code_update", color: "#079992" },
}

const SHIP_TYPES = {
    SHIP_REGION: "Entrega direta pela loja",
    SHIP_GET_IN_SHOP: "Retirar na loja",
}



export const REQUEST_UPDATE_STATUS = () => {
    const status = (value) => { return { text: REQUEST_STATUS_TYPE[value], value } }
    return [
        { text: "Informativo", value: "" },
        status("OPENED"),
        status("WAITING_PAYMENT"),
        status("PAID"),
        status("PROCESSING"),
        status("SEPARATION"),
        status("SENDED"),
        status("DONE")
    ]
}

export const REQUEST_STATUS_TYPE_OPTIONS = () => {
    const keys = Object.getOwnPropertyNames(REQUEST_STATUS_TYPE)
    return keys.map(k => {
        return {
            value: k,
            text: REQUEST_STATUS_TYPE[k]
        }
    })
}


const makeRequest = (data) => {
    let shipData;
    try {
        shipData = JSON.parse(data.ship_data)
    }
    catch (e) {
        shipData = {}
    }
    return {
        ...data,
        ship_data: shipData,
        history: (data.history ?? []).map((i) => makeHistory(i)),
        total_with_discount() {
            return +(this.total * (1 - (this.discount / 100))).toFixed(2)
        },
        total_products() {
            return (this.total - this.ship_cost).toFixed(2)
        },
        payment_remaining() {
            return (this.total_with_discount() - this.balance)
        },
        payment_remaining_percetage() {
            return (this.balance / this.total_with_discount()) * 100
        },
        payment_method_text() {
            const STATUS = {
                MONEY: "Dinheiro",
                DIRECT: "À Vista",
                CLIENT_CREDIT: "Crédito cliente",
                PAY_IN_PIX: "Pagamento com pix",
                PAY_IN_STORE: "Pagamento na retirada do pedido"
            }
            return STATUS[this.payment_method] ?? this.payment_method
        },
        status_text() {
            return REQUEST_STATUS_TYPE[this.status] ?? this.status
        },
        ship_method_text() {
            return SHIP_TYPES[this.ship_method] ?? this.ship_method
        },
        status_icon() {
            return REQUEST_STATUS_TYPE_ICONS[this.status] ?? { icon: "help", color: "black" }
        },
        payments: data.payments != null ? data.payments.map((i) => makePayment(i)) : []
    }
}

export default makeRequest