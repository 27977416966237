const makeClient = (data) => {
    return {
        ...data,
        limit_available() {
            const value = (this.credit + this.max_negative_credit)
            return (value < 0 ? 0 : value)
        },
    }
}

export default makeClient