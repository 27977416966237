import Vue from 'vue'
import App from './App.vue'

import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import router from './router'
import 'material-icons/iconfont/material-icons.css';
import './css/global.scss'
import Format from "@/utils/format";
import VueMeta from 'vue-meta'

import VueApexCharts from 'vue-apexcharts'

import VCalendar from 'v-calendar';
import $theme from './theme'

import Vuex from 'vuex'
import storeConfig from './store'

Vue.use(Vuex)
const store = new Vuex.Store(storeConfig)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {});


Vue.use(Vuesax, {
  theme: $theme
})

Vue.config.productionTip = false
Vue.prototype.$format = Format
Vue.prototype.$session = {}
Vue.prototype.$currentStore = {}
Vue.prototype.$app = {
  promise_queue: [],
  promise(func) {
    this.promise_queue.push(func)
  }
}

Vue.prototype.$env = process.env

Vue.use(VueMeta)

Vue.use(VueApexCharts)
Vue.component('ApexChart', VueApexCharts)



Vue.component("store-section", () => import("./views/store_components/sections/StoreSection.vue"))
Vue.component("store-section-add", () => import("./views/store_components/sections/StoreSectionAdd.vue"))
Vue.component("store-section-layout", () => import("./views/store_components/sections/StoreSectionLayout.vue"))
Vue.component("store-section-collection-tag", () => import("./views/store_components/sections/StoreSectionCollectionTag.vue"))
Vue.component("store-section-banner", () => import("./views/store_components/sections/StoreSectionBanner.vue"))

Vue.component("store-section-collection-tag-settings", () => import("./views/store_settings/sections_settings/StoreSectionCollectionTagSettings.vue"))
Vue.component("store-section-layout-settings", () => import("./views/store_settings/sections_settings/StoreSectionLayoutSettings.vue"))
Vue.component("store-section-banner-settings", () => import("./views/store_settings/sections_settings/StoreSectionBannerSettings.vue"))


Vue.component("app-icon", () => import("./components/Icon.vue"))

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.$app = {}

  }
}).$mount('#app')
