<template>
	<div class="layout-container">
		<div class="menu">
			<SideMenu />
		</div>
		<div class="page">
			<slot></slot>
		</div>
		<notification></notification>
	</div>
</template>

<script>
import Notification from './Notification.vue';
import SideMenu from "./SideMenu.vue"
export default {
	name: 'StockWebLayout',
	components: {
		SideMenu,
		Notification,
	},
	data() {
		return {

		};
	},

	mounted() {

	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>
.layout-container {
	.menu {
		width: 60px;
		height: 100vh;
		position: fixed;
		box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
		// background-color: linear-gradient(#5352ed, yellow);
		background-color: #5352ed;
		// z-index: 1000;
	}
	.page {
		margin-left: 60px;
	}
	width: "100px";
}
</style>